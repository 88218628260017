import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from 'react-number-format';

import {
  Box,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  Button,
  FormControlLabel,
  Checkbox,
  Stack,
  Chip,
  IconButton,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
} from "@mui/icons-material";

import Sidebar from "../components/SideBar";
import Header from "../components/Header";

import { CreateFolder } from "../services/CreateFolderService";
import uploadFileService from "../services/UploadFileService";
import { fetchUserDetails } from "../services/LoginService";

const CreateFolderForm = () => {
  // CONST
  const navigate = useNavigate();
  const [UserDetails, setUserDetails] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("success");
  const [alertMessage, setAlertMessage] = React.useState("");
  const dossier = "dossier";

  // DATA LIST
  const [selectedFiles, setSelectedFiles] = useState({
    green_card_photo: [],
    glass_photo: [],
    vehicle_registration_photo: [],
  });
  const [formData, setFormData] = useState({
    name_or_company: "",
    first_name: "",
    email: "",
    tel: "",
    city: "",
    address: "",
    postal_code: "",
    license_plate: "",
    vehicle_model: "",
    insurance_name: "",
    insurance_policy_number: "",
    date_incident: "",
    date_declaration: "",
    incident_reason: "",
    glass_type: "",
    gift_type: "",
    glass_photo: "",
    green_card_photo: "",
    vehicle_registration_photo: "",
    incident_number: "",
    vehicle_mileage: "",
    installation_address: "",
    internal_reference: "",
    professional: false,
    serial_number: "",
  });

  // USER DETAILS FETCH
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  // AUTOCOMPLETE LIST
  const glass_type_list = [
    "Pare-brise",
    "Lunette arriére",
    "Vitre porte latérale avant ( GAUCHE)",
    "Vitre porte latérale avant ( DROITE)",
    "Vitre porte latérale arrière ( GAUCHE)",
    "Vitre porte latérale arrière ( DROITE)",
    "Pavillon panoramique",
    "Vitre triangulaire avant ( GUACHE)",
    "Vitre triangulaire avant ( DROITE)",
    "Vitre arrière porte latérale arrière ( GAUCHE)",
    "Vitre arrière porte latérale arrière ( DROITE)",
    "Lunette arriére inférieure",
    "Lunette arriére supérieure",
    "Vitre custode arrière ( GAUCHE)",
    "Vitre custode arrière ( DROITE)",
    "Vitre arrière custode arrière ( GAUCHE)",
    "Vitre arrière custode arrière ( DROITE)",
    "Vitres portes chargement arrière (Utilitaire) - [Gauche]",
    "Vitres portes chargement arrière (Utilitaire) - [Droite]",
    "Ensemble vitre panneau latéral - [Gauche]",
    "Ensemble vitre panneau latéral - [Droite]",
    "Vitre avant panneau latéral - [Gauche]",
    "Vitre avant panneau latéral - [Droite]",
    "Vitre coulissante avant panneau latéral - [Gauche]",
    "Vitre coulissante avant panneau latéral - [Droite]",
    "Vitre centrale panneau latéral - [Gauche]",
    "Vitre centrale panneau latéral - [Droite]",
    "Vitre coulissante centrale panneau latéral - [Gauche]",
    "Vitre coulissante centrale panneau latéral - [Droite]",
    "Vitre arrière panneau latéral - [Gauche]",
    "Vitre arrière panneau latéral - [Droite]",
    "Vitre coulissante arrière panneau latéral - [Gauche]",
    "Vitre coulissante arrière panneau latéral - [Droite]",
    "Vitre avant de panneau latéral arrière - [Gauche]",
    "Vitre avant de panneau latéral arrière - [Droite]",
    "Vitre arrière de panneau latéral arrière - [Gauche]",
    "Vitre arrière de panneau latéral arrière - [Droite]",
    "Vitre rallonge de panneau latéral arrière  - [Gauche]",
    "Vitre rallonge de panneau latéral arrière  - [Droite]",
    "Vitre supérieure panneau latéral - [Gauche]",
    "Vitre supérieure panneau latéral - [Droite]",
    "Pavillon avant – [Gauche]",
    "Pavillon avant – [Droite]",
    "Pavillon arrière – [Gauche]",
    "Pavillon arrière – [Droite]",
    "Toit ouvrant avant vitré (complet)",
    "Vitre toit ouvrant central",
    "Toit ouvrant arrière vitré (complet)",
    "Vitre toit ouvrant arrière",
    "Toit ouvrant complet à lamelles",
    "Lamelle 1 de toit ouvrant",
    "Lamelle 2 de toit ouvrant",
    "Lamelle 3 de toit ouvrant",
    "Lamelle 4 de toit ouvrant",
    "Lamelle 5 de toit ouvrant",
    "Phare avant (complet) - [Gauche]",
    "Phare avant (complet) - [Droite]",
    "Phare avant (extérieur) - [Gauche]",
    "Phare avant (extérieur) - [Droite]",
    "Vitre phare avant – [Gauche]",
    "Vitre phare avant – [Droite]",
    "Phare antibrouillard avant (complet) – [Gauche]",
    "Phare antibrouillard avant (complet) – [Droite]",
    "Phare antibrouillard avant – [Gauche]",
    "Phare antibrouillard avant – [Droite]",
    "Clignotant avant (complet) – [Gauche]",
    "Clignotant avant (complet) – [Droite]",
    "Feu arrière (complet) – [Gauche]",
    "Feu arrière (complet) – [Droite]",
    "Feu arrière (extérieur) – [Gauche]",
    "Feu arrière (extérieur) – [Droite]",
    "Feu arrière complet (intérieur) – [Gauche]",
    "Feu arrière complet (intérieur) – [Droite]",
    "Ecran feu arrière (extérieur) – [Gauche]",
    "Ecran feu arrière (extérieur) – [Droite]",
    "Ecran feu arrière (intérieur) – [Gauche]",
    "Ecran feu arrière (intérieur) – [Droite]",
    "Rétroviseur extérieur (complet) – [Gauche]",
    "Rétroviseur extérieur (complet) – [Droite]",
    "Rétroviseur intérieur",
    "Vitre rétroviseur extérieur avant – [Gauche]",
    "Vitre rétroviseur extérieur avant – [Droite]",
    "Vitre inférieure rétroviseur (VU) – [Gauche]",
    "Vitre inférieure rétroviseur (VU) – [Droite]",
  ];
  const incident_reason_list = [
    "une projection sur la route",
    "un vandalisme",
    "une intempérie",
    "un accident",
    "en stationnement",
    "un nid de poule",
    "des gravillons",
    "de la grêle",
    "un vol",
    "une variation thermique",
  ];
  const insurance_name_list = [
    "MAVIT ASSURANCES",
    "4 ASSUR",
    "ASSU2000",
    "ACTE IARD",
    "ACTIVE ASSURANCES",
    "AG2R LA MONDIALE",
    "AIG EUROPE LIMITED",
    "AIR-ALPHA ASSURANCES",
    "ALLIANZ IARD S.A.",
    "APRIL PARTENAIRES",
    "AMV",
    "AGPM ASSURANCES",
    "ASSURANCE MUTUELLE DES MOTARDS",
    "ABEILLE ASSURANCES",
    "ADEP",
    "ADMIRAL INTERMEDIARY SERVICES SA",
    "AREAS DOMMAGES",
    "ALBINGIA",
    "ALLSECUR",
    "ALTIMA ASSURANCES",
    "AIOI NISSAY DOWA EUROPE",
    "APIVIA COURTAGE",
    "APRIL COLLECTION ET LOISIRS",
    "ACM IARD",
    "ASSUREO",
    "ASSURPEOPLE",
    "GROUPAMA PARIS VAL DE LOIRE",
    "LA SAUVEGARDE",
    "BNP PARIBAS",
    "CARENE ASSURANCES",
    "CAISSE MUTUELLE MARNAISE D ASSURANCE",
    "CARDIF IARD",
    "BPCE IARD",
    "CHUBB EUROPEAN GROUP LIMITED",
    "DIRECT ASSURANCE",
    "CMMA ASSURANCE",
    "BALTIQUEASSURANCES",
    "ASSURANCES MUTUELLES DE PICARDIE",
    "ASSURONE GROUP",
    "ASSURONLINE",
    "GROUPE SATEC",
    "ASSURANCES DU CREDIT MUTUEL IARD",
    "AXERIA IARD",
    "ECA",
    "EURALPHA ASSURANCES",
    "GROUPAM RHONE-ALPES AUVERGNE",
    "EURODATACAR",
    "LSA COURTAGE",
    "FILHET-ALLARD",
    "AXA FRANCE IARD",
    "GENERALI IARD",
    "AVANSSUR",
    "L EQUITE",
    "EURO-ASSURANCE",
    "GROUPAMA ANTILLES GUYANE",
    "GROUPAMA CENTRE-ATLANTIQUE",
    "GROUPAMA GRAND EST",
    "GROUPAMA LOIRE BRETAGNE",
    "GROUPAMA MEDITERRANEE",
    "GROUPAMA NORD-EST",
    "GROUPAMA D OC",
    "EUROFIL",
    "CARMA",
    "LA MEDICALE",
    "GROUPE AMI3F",
    "IPAC 64",
    "CAISSE MEUSIENNE D ASSURANCES MUTUELLES",
    "FRANCOIS BERNANRD ASSURANCES",
    "GRAS SAVOYE",
    "I2FC",
    "IDENTICAR",
    "INTER MUTUELLES ENTREPRISES",
    "L AUXILIAIRE",
    "LA BRESSANE",
    "LA BRESSANE ASSURANCES",
    "LA RURALE",
    "LIBEA",
    "LYBERNET ASSURANCES",
    "MAAF ASSURANCES SA",
    "MACIF",
    "MACIFILIA",
    "MATMUT",
    "PSA ASSURANCE",
    "MAPA",
    "NOVELIA",
    "PRUDENCE CREOLE",
    "PROTEC BTP",
    "PRIMA",
    "PACIFICA",
    "MATMUT & CO",
    "MAIF",
    "MMA IARD",
    "SURAVENIR ASSURANCES",
    "SOGESSUR",
    "MFA",
    "MACSF",
    "THELEM ASSURANCES",
    "SWISS LIFE",
    "SMABTP",
    "SERENIS ASSURANCES",
    "MONCEAU GENERALE ASSURANCES",
    "MAXANCE ASSURANCES",
    "OYAT ASSURANCES",
    "SOLLY AZAR ASSURANCES",
    "MUTUELLE SAINT CHRISTOPHE",
    "MARSH SAS",
    "SADA ASSURANCES",
    "SARPGN",
    "QUATTRO ASSURANCES",
    "REMA ASSURANCES MUTUELLES",
    "SHAM",
    "SAGESSE ASSURANCES",
    "SMA SA",
    "SMACL ASSURANCES",
    "SOCIETE SUISSE D ASSURANCE CONTRE LA GRELE",
    "TOYOTA ASSURANCES",
    "MUTUELLE D'ASSURANCE DES ARMEES",
    "MUTUELLE D'ASSURANCE DE LA VILLE DE THANN",
    "MAVIM",
    "MUTUELLE ASSURANCES CORPS SANTE FRANCAIS",
    "MUTUELLE ALSACE LORRAINE JURA",
    "MATH-PREVERAIS",
    "MUDETAF",
    "MADP ASSURANCES",
    "MUTUELLE FRATERNELLE ASSURANCE",
    "MAE",
    "MALJ",
    "RELYENS",
    "A COMME ASSURE",
    "WAKAM",
    "LCL",
    "ARTEMIS",
    "VERSPIEREN",
    "FMA",
    "LEOCARE",
    "JP LABALETTE",
    "L'OLIVIER",
    "AVIVA",
    "WAZARI",
    "GFA LACOUR",
    "XL INSURANCE COMPANY SE",
    "RENAULT ASSURANCE",
    "AMF ASSURANCES",
    "EURODAMMAGES",
    "CARREFOUR ASSURANCES",
    "GAN ASSURANCES",
    "CARMINE ASSURANCES",
    "BPCE ASSURANCES",
    "GROUPAMA CENTRE MANCHE",
    "GROUPAMA OCEAN INDIEN",
    "MUTUELLE DE L'EST",
    "CMAM",
    "RETRO ASSURANCES",
    "MUTUELLE D'ASSURANCE DES TECHNICIEN DE LA SECURITE ROUTIERE",
    "IMPERIO ASSURANCE",
    "ACHEEL",
    "LE FINISTERE ASSURANCE",
    "MUTUELLE D'ASSURANCE DE LA VILLE DE MULHOUSE",
    "DIF",
    "ASSURANCES PILLIOT",
    "PHENIX ASSURANCE",
    "FLITTER",
    "FRONTASSUR",
    "MIEUX ASSURE",
    "GMF ASSURANCES",
    "ARVAL SERVICE LEASE",
    "LA BANQUE POSTALE ASSURANCES IARD",
    "NETVOX ASSURANCE",
    "MUTUELLE DE POITIERS ASSURANCES",
    "CBT FERBU (THELEM)",
    "ASSU 2000",
    "ASSURTIS",
    "L'EQUITE",
    "AECS",
    "CIC CREDIT LYONNAIS",
    "COVERITY",
    "BNP AVANSSUR",
    "CABINET BASSEVILLE",
    "CABINET JACQUES",
    "KELIP'S",
    "CBT BFK ASSURANCES",
    "LOVYS",
    "ANGELUS",
    "AON FRANCE",
    "CBT CELTIQUE COURTAGE",
    "REFLEXE ASSURANCE",
    "CBT CEPIA",
    "INFINITY",
    "ELSASSUR",
    "EMIRION ASSURANCES (GENERALI)",
    "FATEC-GROUP",
    "GFA CARAIBES GENERALI",
    "WTW",
    "LEASPLAN FRANCE SAS",
    "LCA",
    "HELVETIA",
    "LINK ASSURANCES",
    "LUXIOR ASSURANCE",
    "SYLVIE CABRET",
    "PLURIEL ASSURANCES",
    "PLUS SIMPLE",
    "P,PLASSE",
    "SPRINGASSUR",
    "CFC",
    "KELIPS ASSURANCE",
    "XENASSUR",
    "ASSURANCES VERLINGUE",
    "CAP'ASSUR",
    "DJN ASSURANCES(ALLIANZ)",
    "APRIL ENTREPRISE CARAIBES",
    "LIGAP",
    "MUTUELLE D ASSURANCE DE L'ARTISANAT ET DES TRANSPORTS",
    "ORNIKAR",
    "MAF",
    "SM3A LYON",
    "TETRIS ASSURANCE",
    "AESIO MUTUELLE",
    "+ SIMPLE",
    "ATHLON CAR LEASE",
    "YEET ASSURANCES",    
    "AUTRE ASSURANCE"
  ];

  // HANDLE DATA CHANGE FUNCTIONS
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const handleAutocompleteChange = (e, value, fieldName) => {
    setFormData({ ...formData, [fieldName]: value });
  };

// HANDLE FILE CHANGE FUNCTIONS
const handleFileChange = (name, event) => {
  setSelectedFiles((prevFiles) => {
    const updatedFiles = [...(prevFiles[name] || [])];
    Array.from(event.target.files).forEach((file) => {
      // Create a copy of the file object and add the field name to it
      const fileWithField = new File([file], file.name, { type: file.type });
      fileWithField.fieldName = name;
      updatedFiles.push(fileWithField);
    });

    return {
      ...prevFiles,
      [name]: updatedFiles,
    };
  });
};


  const handleRemoveFile = (name, index) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles[name]];
      newFiles.splice(index, 1);
      return { ...prevFiles, [name]: newFiles };
    });
  };

  // HANDLE ALERT  FUNCTIONS
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

// HANDLE SUBMIT FUNCTION
const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const allFiles = Object.values(selectedFiles).flat();

    // Upload files and get the response
    const fileUploadResponse = await uploadFileService(allFiles, dossier);

    // Base path to be used for relative paths
    const basePath = `uploads/dossier/`;

    // Map the file paths to the corresponding form fields
    const filePaths = {};

    fileUploadResponse.filePaths.forEach((filePath, index) => {
      // Extract the field name from the file object
      const fileField = allFiles[index].fieldName;

      if (!filePaths[fileField]) {
        filePaths[fileField] = [];
      }

      // Push the file path to the corresponding field
      filePaths[fileField].push(`${basePath}${filePath.split("\\").pop()}`);
    });

    // Create a copy of the form data with updated file paths
    const updatedFormData = { ...formData };

    Object.keys(filePaths).forEach((fileField) => {
      updatedFormData[fileField] = filePaths[fileField].map(filePath => {
        // Check if the file path already contains 'dossier/'
        if (!filePath.includes('dossier/')) {
          return filePath.replace('uploads/', 'uploads/dossier/');
        }
        return filePath;
      }).join("|"); // Join paths with a delimiter
    });

    // Create the folder with the updated form data
    const response = await CreateFolder(
      updatedFormData,
      UserDetails.userid,
      UserDetails.usertype
    );
    console.log(updatedFormData);
    
    // navigate("/MyFolders");
    setAlertMessage(`Le dossier a été créé avec succès.`);
    setAlertSeverity("success");
    setAlertOpen(true);
  } catch (error) {
    setAlertMessage(error.message);
    setAlertSeverity("error");
    setAlertOpen(true);
  }
};

  // loading indicator
  if (!UserDetails) {
    return null;
  }

  // COMPONENTS  FUNCTION
  const FileInput = ({ name, label }) => (
    <Stack spacing={2} alignItems="center">
      <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
        <Autocomplete
          multiple
          limitTags={1}
          sx={{ width: "90%" }}
          size="small"
          id={`file-input-${name}`}
          options={[]}
          freeSolo
          // value={selectedFiles[name]}
          value={selectedFiles[name] || []}
          getOptionLabel={(file) => file.name}
          renderTags={(value, getTagProps) =>
            value.map((file, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={file.name}
                  {...tagProps}
                  deleteIcon={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(name, index)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                  onDelete={() => handleRemoveFile(name, index)}
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={label}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
        <IconButton
          component="label"
          aria-label="upload file"
          htmlFor={`file-upload-${name}`}
          sx={{ width: "32px", height: "32px" }}>
          <AttachFileIcon />
        </IconButton>
        <input
          type="file"
          id={`file-upload-${name}`}
          multiple
          onChange={(event) => handleFileChange(name, event)}
          style={{ display: "none" }}
        />
      </Box>
    </Stack>
  );

  // CORE RETURN
  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: "100px", display: "flex" }}>
        <Sidebar />
        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            <AlertTitle>
              {alertSeverity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
          }}>
          <Typography variant="h4" sx={{ my: 2 }}>
            Information Required
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                id="name_or_company"
                label="Nom ou Société"
                name="name_or_company"
                type="text"
                autoFocus
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                id="first_name"
                label="Prénom"
                name="first_name"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                id="email"
                label="Email"
                name="email"
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <PhoneInput
                required
                country={"fr"}
                onChange={(phone) =>
                  handleChange({ target: { name: "tel", value: phone } })
                }
                inputProps={{
                  name: "tel",
                  required: true,
                  autoFocus: false,
                }}
                containerStyle={{ width: "100%" }}
                inputStyle={{ width: "100%", height: "40px" }}
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="city"
                label="Ville"
                id="city"
                size="small"
              />
            </Grid>
            <Grid item xs={6} sm={3}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="postal_code"
                label="Code Postal"
                id="postal_code"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="address"
                label="Adresse"
                id="address"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                onChange={handleChange}
                name="date_declaration"
                label="Date de déclaration"
                type="date"
                id="date_declaration"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="license_plate"
                label="Plaque d'immatriculation"
                id="license_plate"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="vehicle_model"
                label="Modèle du véhicule"
                id="vehicle_model"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="insurance_name"
                name="insurance_name"
                onChange={(e, value) =>
                  handleAutocompleteChange(e, value, "insurance_name")
                }
                options={insurance_name_list}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Nom de l'assurance"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="insurance_name"
                label="Autre Assurance"
                id="insurance_name"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                name="insurance_policy_number"
                label="Numéro de police d'assurance"
                id="insurance_policy_number"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                onChange={handleChange}
                name="date_incident"
                label="Date de sinistre"
                type="date"
                id="date_incident"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="incident_reason"
                name="incident_reason"
                options={incident_reason_list}
                onChange={(e, value) =>
                  handleAutocompleteChange(e, value, "incident_reason")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Raison de sinistre"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disablePortal
                id="glass_type"
                name="glass_type"
                onChange={(e, value) =>
                  handleAutocompleteChange(e, value, "glass_type")
                }
                options={glass_type_list}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Type de vitrage"
                    size="small"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Typography variant="h4" sx={{ my: 2 }}>
            Information not Required
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FileInput label="Photo du vitrage" name="glass_photo" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FileInput
                label="Photo de la carte verte"
                name="green_card_photo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FileInput
                label="Photo de la carte grise"
                name="vehicle_registration_photo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                name="gift_type"
                label="Type de cadeau"
                id="gift_type"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                name="incident_number"
                label="Numéro de sinistre"
                id="incident_number"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                name="serial_number"
                label="Numéro de série"
                id="serial_number"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                name="vehicle_mileage"
                label="Kilométrage du véhicule"
                id="vehicle_mileage"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                name="installation_address"
                label="Adresse d'installation"
                id="installation_address"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                onChange={handleChange}
                name="internal_reference"
                label="Référence interne"
                id="internal_reference"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="professional"
                    checked={Boolean(formData.professional)}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="Est-ce un professionnel ?"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              mt: 2,
              backgroundColor: "#002654",
              "&:hover": {
                backgroundColor: "#ff224d",
              },
            }}>
            Créer le dossier
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateFolderForm;
