import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx";

import { Box, TextField, Autocomplete, Chip, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LinkIcon from "@mui/icons-material/Link";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PendingIcon from "@mui/icons-material/Pending";

import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import Loading from "../components/Loading";
import StatusChip from "../components/StatusChip"; // Import your StatusChip component

import { getMyFolderData } from "../services/MyFolderService";
import { fetchUserDetails } from "../services/LoginService";
import { DossierUpdate } from "../services/DossierUpdateService";

function MyFolderPage() {
  const [FolderData, setFolderData] = useState([]);
  const [UserDetails, setUserDetails] = useState(null);
  const [dossierData, setDossierData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortModel, setSortModel] = React.useState([
    {
      field: "dossier_id",
      sort: "desc",
    },
  ]);
  // USER DETAILS
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  // Fetch Folder Data after UserDetails is available
  useEffect(() => {
    if (UserDetails) {
      fetchMyFolderData(UserDetails);
    }
  }, [UserDetails]);

  //DOSSIER
  const fetchMyFolderData = async (userDetails) => {
    try {
      const data = await getMyFolderData(
        userDetails.usertype,
        userDetails.userid,
        userDetails.cc_role
      );
      const formattedData = data.map((row, index) => ({
        ...row,
        id: index + 1,
        garage: `${row.garage_first_name || ""} ${row.garage_last_name || ""}`,
        gestionnaire: `${row.gestionnaire_first_name || ""} ${
          row.gestionnaire_last_name || ""
        }`,
      }));

      setFolderData(formattedData);
    } catch (error) {
      console.error("Error fetching my Folder data:", error);
    }
  };

  const { userid, usertype, cc_role } = UserDetails || {};

  // RENDER
  const renderNameOrCompany = (params) => {
    const dossierId = params.row.dossier_id;
    const nameOrCompany = params.value;
    const firstName = params.row.first_name; // Assuming the data includes first_name
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Link
          to={`/Dossier/${dossierId}`}
          style={{
            color: "#1976d2", // Blue color
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              textDecoration: "underline",
            },
          }}>
          <LinkIcon sx={{ marginRight: "4px" }} />{" "}
          {`${nameOrCompany} ${firstName}`}
        </Link>
      </Box>
    );
  };
  const renderCreatedByFullName = (params) => {
    let color = "";
    let roleLabel = "";

    switch (params.row.created_by) {
      case "admin":
        color = "red"; // Example color for admin
        roleLabel = "Ad";
        break;
      case "gestionnaire":
        color = "blue"; // Example color for gestionnaire
        roleLabel = "Ge";
        break;
      case "call_center":
        color = "pink"; // Example color for call center
        roleLabel = "Cc";
        break;
      case "garage":
        color = "orange"; // Example color for garage
        roleLabel = "Ga";
        break;
      // Add more cases for other roles as needed
      default:
        color = "grey";
        roleLabel = "Unknown";
        break;
    }

    const fullName = params.value;
    const spaceIndex = fullName.lastIndexOf(" ");
    const userName = fullName.substring(0, spaceIndex);

    return (
      <span>
        <span>{userName}</span>
        <span style={{ color: color }}> ({roleLabel})</span>
      </span>
    );
  };
  const renderDateCreated = (params) => {
    const date = new Date(params.value);
    return date.toLocaleDateString();
  };
  // Function to format the date
  const renderDateRendezVous = (params) => {
    const date = new Date(params.value);

    // Check if the date is '1/1/1970'
    if (date.getTime() === new Date("1970-01-01T00:00:00Z").getTime()) {
      return "";
    }

    return date.toLocaleDateString(); // Format date as per locale, you can customize as needed
  };

  const handleTraiterDossier = async (dossierId) => {
    try {
      await DossierUpdate(dossierId, { status: "En cours" }, userid, usertype);

      const updatedData = dossierData.map((dossier) => {
        if (dossier.id === dossierId) {
          return { ...dossier, status: "En cours" };
        }
        return dossier;
      });
      setDossierData(updatedData);
    } catch (error) {
      console.error("Error updating dossier status:", error);
    }
  };

  //EXPORT
  const exportToExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dossiers");
    XLSX.writeFile(workbook, "dossiers.xlsx");
  };
  const exportSelectedRowToExcel = () => {
    if (selectedRow) {
      exportToExcel([selectedRow]);
    } else {
      alert("Please select a row to export.");
    }
  };

  //COLUMNS
  const [columns, setColumns] = useState([
    {
      field: "dossier_id",
      headerName: "N° Dossier",
      width: 70,
      resizable: true,
    },
    {
      field: "name_or_company",
      headerName: "Nom ou Entreprise",
      resizable: true,
      width: 200,
      renderCell: renderNameOrCompany,
    },
    {
      field: "status",
      headerName: "Statut Bleu Glass",
      resizable: true,
      width: 160,
      renderCell: (params) => (
        <StatusChip
          status={params.row.status}
          usertype={UserDetails ? UserDetails.usertype : "undefined"}
          onClick={() => handleTraiterDossier(params.row.dossier_id)}
        />
      ),
    },
    {
      field: "vehicle_model",
      width: 160,
      headerName: "Modèle de Véhicule",
      resizable: true,
      sortable: true,
    },
    {
      field: "license_plate",
      headerName: "Plaque de matriculation",
      resizable: true,
      width: 100,
    },
    {
      field: "insurance_name",
      headerName: "Nom de l'assurance",
      width: 160,
      resizable: true,
      sortable: true,
    },
    {
      field: "postal_code",
      headerName: "Code Postal",
      resizable: true,
      width: 100,
      sortable: true,
    },
    {
      field: "garage_name",
      headerName: "Garage",
      width: 100,
      resizable: true,
      sortable: true,
    },
    {
      field: "appointment_date",
      headerName: "Date de rendez-vous",
      resizable: true,
      width: 100,
      renderCell: renderDateRendezVous, // Use the custom render function
    },
    {
      field: "cc_call_center_name",
      headerName: "Centre d'Appel",
      resizable: true,
      width: 160,
    },
    {
      field: "gestionnaire",
      headerName: "Gestionnaire",
      resizable: true,
      width: 160,
    },
    {
      field: "created_by_full_name",
      headerName: "Créé par",
      resizable: true,
      width: 160,
      renderCell: renderCreatedByFullName,
    },
    {
      field: "date_created",
      headerName: "Date de création",
      resizable: true,
      width: 100,
      renderCell: renderDateCreated, // Use the custom render function
    },
  ]);

  useEffect(() => {
    const fetchDataAndSetupColumns = async () => {
      if (UserDetails) {
        await fetchMyFolderData();

        // Set columns based on UserDetails
        if (UserDetails.usertype === "garage") {
          setColumns((prevColumns) =>
            prevColumns.filter(
              (column) =>
                column.field !== "created_by_full_name" &&
                column.field !== "garage_name" &&
                column.field !== "cc_call_center_name"
            )
          );
        } else if (UserDetails.usertype === "call_center") {
          if (cc_role === "gerant") {
            setColumns((prevColumns) =>
              prevColumns.filter(
                (column) =>
                  column.field !== "garage_name" &&
                  column.field !== "cc_call_center_name" &&
                  column.field !== "gestionnaire"
              )
            );
          } else {
            setColumns((prevColumns) =>
              prevColumns.filter(
                (column) =>
                  column.field !== "garage_name" &&
                  column.field !== "cc_call_center_name" &&
                  column.field !== "created_by_full_name"
              )
            );
          }
        }
      }
    };

    fetchDataAndSetupColumns();
  }, [UserDetails, cc_role]);

  if (!UserDetails) {
    return <Loading />;
  }

  return (
    <Box component="div">
      <Header />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Sidebar />
        <Box
          sx={{
            width: window.innerWidth < 768 ? "99%" : "93%",
            height: "75vh",
            marginLeft: window.innerWidth < 768 ? "5px" : "260px",
            marginRight: "5px",
          }}
          component="div">
          <Box
            sx={{
              display: "flex",
              marginBottom: 2,
              gap: 1,
              alignContent: "center",
              alignItems: "center",
            }}>
            <Autocomplete
              freeSolo
              disableClearable
              options={[]}
              sx={{
                width: 300,
                height: 32,
                marginBottom: 1,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Rechercher..."
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    endAdornment: (
                      <SearchIcon
                        sx={{ color: "action.active", cursor: "pointer" }}
                      />
                    ),
                  }}
                />
              )}
            />
            {usertype === "admin" && (
              <>
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: { xs: 0, sm: 2 },
                    marginTop: { xs: 2, sm: 0 },
                    width: { xs: "100%", sm: "auto" },
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "darkgreen",
                    },
                    fontSize: { xs: "0.5rem", sm: "0.75rem" },
                    padding: { xs: "3px 6px", sm: "8px 10px" },
                  }}
                  onClick={() => exportToExcel(dossierData)}
                  startIcon={
                    <FileDownloadIcon
                      sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem" } }}
                    />
                  }>
                  Exporter Tout
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    marginLeft: { xs: 0, sm: 2 },
                    marginTop: { xs: 2, sm: 0 },
                    width: { xs: "100%", sm: "auto" },
                    backgroundColor: "green",
                    "&:hover": {
                      backgroundColor: "darkgreen",
                    },
                    fontSize: { xs: "0.5rem", sm: "0.75rem" },
                    padding: { xs: "4px 6px", sm: "8px 10px" },
                  }}
                  onClick={exportSelectedRowToExcel}
                  startIcon={
                    <FileDownloadIcon
                      sx={{ fontSize: { xs: "0.5rem", sm: "0.75rem" } }}
                    />
                  }>
                  Exporter la sélection
                </Button>
              </>
            )}
          </Box>

          <DataGrid
            rows={FolderData}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onRowClick={(params) => {
              setSelectedRow(params.row);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default MyFolderPage;
