import { BASE_URL } from "../constants/BaseUrl";


export const deleteDossierService = async (id,userId) => {
    try {
        const response = await fetch(`${BASE_URL}/softDelete/dossier/${id}`, {
          method: "PUT",
          body: JSON.stringify({ userId }),
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.ok) {
          const jsonResponse = await response.json();
          return jsonResponse;
        } else {
          const errorMessage = await response.json();
          throw new Error(errorMessage.message);
        }
      } catch (error) {
        throw error;
      }
}