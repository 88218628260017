import {
  Box,
  Stack,
  Paper,
  List,
  ListItemButton,
  ListItemText,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  Avatar,
  FormControl,
  Input,
  InputLabel,
  IconButton,
  Autocomplete,
  Alert,
  AlertTitle,
  Snackbar,
} from "@mui/material";
import { Chip } from "@mui/material";
import {
  Person as PersonIcon,
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import { CreateUser } from "../services/CreateUserService";
import uploadFileService from "../services/UploadFileService";

import Sidebar from "../components/SideBar";
import Header from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { GetGarageById, UpdateGarageById } from "../services/GarageService";
import LoadingIndicator from "../components/Loading";
import ValueField from "../components/ValueField";
import { IMAGE_URL } from "../constants/BaseUrl";

const GarageUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("success");
  const [profileFile, setProfileFile] = useState(null);
  const [profileFilePath, setProfileFilePath] = useState("");

  const [alertMessage, setAlertMessage] = React.useState("");
  const [GarageDetails, setGarageDetails] = useState(null);
  const [selectedusertype, setSelectedusertype] = React.useState("garage");
  const [selectedFiles, setSelectedFiles] = useState({
    // garage
    garage_kbis: [],
    garage_identity: [],
    garage_profile: [],
  });
  const [formData, setFormData] = useState({
    garage_name: "",
    garage_first_name: "",
    garage_last_name: "",
    garage_role: "",
    garage_email: "",
    garage_kbis: "",
    garage_identity: "",
    garage_profile: "",
    is_blocked: "",
    garage_address: "",
    garage_phone: "",
    garage_logo: "",
    garage_tva_imm: "",
    garage_bic: "",
    garage_iban: "",
    garage_ville: "",
    garage_n_siret: "",
    garage_email_blue_glass: "",
    garage_code_postale: "",
  });

  const [isUpdate, setIsUpdate] = useState(false);
  // FETCH USER DATA FUNCTION
  useEffect(() => {
    const setGarageDetailsAPI = async (id) => {
      const response = await GetGarageById(id);
      const { password, ...rest } = response;
      setGarageDetails(rest);
      setFormData(rest);
    };
    setGarageDetailsAPI(id);
  }, [id]);
  // HANDLE DATA CHANGE FUNCTIONS
  const toggleUpdate = () => {
    setIsUpdate(!isUpdate);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // HANDLE ALERT  FUNCTIONS
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // HANDLE FILE CHANGE FUNCTIONS
  const handleFileChange = (name, event) => {
    let firstName, lastName;

    firstName = formData.garage_first_name || "empty_first_name";
    lastName = formData.garage_last_name || "empty_last_name";

    setSelectedFiles((prevFiles) => {
      // const updatedFiles = [...prevFiles[name]];
      const updatedFiles = [...(prevFiles[name] || [])];
      Array.from(event.target.files).forEach((file) => {
        const fileExtension = file.name.split(".").pop();
        const randomNum = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
        const newFileName = `${firstName}${lastName}-${name}-${randomNum}.${fileExtension}`;
        const newFile = new File([file], newFileName, { type: file.type });
        updatedFiles.push(newFile);
      });

      return {
        ...prevFiles,
        [name]: updatedFiles,
      };
    });
  };
  const handleProfileFileChange = (name, event) => {
    const file = event.target.files[0];
    if (file) {
      let firstName, lastName;
      firstName = formData.garage_first_name || "empty_first_name";
      lastName = formData.garage_last_name || "empty_last_name";

      const fileExtension = file.name.split(".").pop();
      const randomNum = Math.floor(10000 + Math.random() * 90000); // Generate a random 5-digit number
      const newFileName = `${firstName}${lastName}-${name}-${randomNum}.${fileExtension}`;
      const newFile = new File([file], newFileName, { type: file.type });

      setProfileFile(newFile);
      setProfileFilePath(URL.createObjectURL(newFile));

      // Update the formData to reflect the profile file path
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: newFileName,
      }));
    }
  };

  const handleRemoveFile = (name, index) => {
    setSelectedFiles((prevFiles) => {
      const newFiles = [...prevFiles[name]];
      newFiles.splice(index, 1);
      return { ...prevFiles, [name]: newFiles };
    });
  };
  // HANDLE SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const allFiles = Object.values(selectedFiles).flat();
      if (profileFile) allFiles.push(profileFile);

      const fileUploadResponse = await uploadFileService(
        allFiles,
        selectedusertype
      );

      const basePath = `uploads/user/${selectedusertype}/`;

      const filePaths = {};

      fileUploadResponse.filePaths.forEach((filePath, index) => {
        const fileField = allFiles[index].name.split("-")[1];
        if (!filePaths[fileField]) {
          filePaths[fileField] = [];
        }
        filePaths[fileField].push(`${basePath}${filePath.split("\\").pop()}`);
      });

      Object.keys(filePaths).forEach((fileField) => {
        formData[fileField] = filePaths[fileField].join("|");
      });

      // Ensure the profile field is updated with the correct path
      if (profileFile) {
        const profileField = `${selectedusertype}_profile`;
        formData[profileField] = `${basePath}${profileFile.name
          .split("\\")
          .pop()}`;
      }
      formData.userid = id;
      formData.usertype = selectedusertype;
      formData.firstName = formData.garage_first_name;
      formData.lastName = formData.garage_last_name;
      formData.email = formData.garage_email;

      const response = await UpdateGarageById(id, formData);
      setGarageDetails(response.data);
      setFormData(response.data);
      setAlertMessage(
        `L'utilisateur de type ${selectedusertype} a été modifié avec succès.`
      );
      setAlertSeverity("success");
      setAlertOpen(true);
      toggleUpdate();
    } catch (error) {
      setAlertMessage(error.message);
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };
  // COMPONENTS  FUNCTION
  const FileInput = ({ name, label }) => (
    <Stack spacing={2} alignItems="center">
      <Box sx={{ display: "flex", alignItems: "start", width: "100%" }}>
        <Autocomplete
          multiple
          limitTags={1}
          sx={{ width: "90%" }}
          size="small"
          id={`file-input-${name}`}
          options={[]}
          freeSolo
          // value={selectedFiles[name]}
          value={selectedFiles[name] || []}
          getOptionLabel={(file) => file.name}
          renderTags={(value, getTagProps) =>
            value.map((file, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={file.name}
                  {...tagProps}
                  deleteIcon={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(name, index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  onDelete={() => handleRemoveFile(name, index)}
                  sx={{
                    maxWidth: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label={label}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
        <IconButton
          component="label"
          aria-label="upload file"
          htmlFor={`file-upload-${name}`}
          sx={{ width: "32px", height: "32px" }}
        >
          <AttachFileIcon />
        </IconButton>
        <input
          type="file"
          id={`file-upload-${name}`}
          multiple
          onChange={(event) => handleFileChange(name, event)}
          style={{ display: "none" }}
        />
      </Box>
    </Stack>
  );
  const ProfileInput = ({ name, src }) => {
    const url = profileFilePath || `${IMAGE_URL}/${src}` || "";
    return (
      <Stack
        spacing={2}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <label
          htmlFor={`profile-upload-${name}`}
          style={{ position: "relative", display: "inline-block" }}
        >
          <Avatar
            alt="Profile Picture"
            src={url}
            sx={{ width: 64, height: 64 }}
          >
            {!url && <PersonIcon />}
          </Avatar>
          <Input
            type="file"
            accept="image/*"
            id={`profile-upload-${name}`}
            onChange={(event) => handleProfileFileChange(name, event)}
            sx={{ display: "none" }}
          />
        </label>
        <IconButton
          component="label"
          htmlFor={`profile-upload-${name}`}
          sx={{
            width: 24,
            height: 24,
            bgcolor: "#002654",
            color: "white",
            "&:hover": {
              bgcolor: "#ff224d",
            },
            marginLeft: "-40px",
          }}
        >
          <EditIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Stack>
    );
  };
  if (!GarageDetails) {
    return <LoadingIndicator />;
  }
  const renderGarageDetails = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileInput
              name="garage_profile"
              src={GarageDetails.garage_profile}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"Société"} value={GarageDetails.garage_name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"Rôle"} value={GarageDetails.garage_role} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              label={"Prénom"}
              value={GarageDetails.garage_first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              label={"Nom de famille"}
              value={GarageDetails.garage_last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              isUrl
              label={"KBIS du garage"}
              value={GarageDetails.garage_kbis}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              isUrl
              label={"Garage Identité"}
              value={GarageDetails.garage_identity}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"Email"} value={GarageDetails.garage_email} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"Phone"} value={GarageDetails.garage_phone} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"IBAN"} value={GarageDetails.garage_iban} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"BIC"} value={GarageDetails.garage_bic} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <ValueField
              label={"TVA IMM"}
              value={GarageDetails.garage_tva_imm}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              label={"N° Siret"}
              value={GarageDetails.garage_n_siret}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              label={"Email Blue Glass"}
              value={GarageDetails.garage_email_blue_glass}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              label={"Code Postale"}
              value={GarageDetails.garage_code_postale}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField
              label={"Address"}
              value={GarageDetails.garage_address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ValueField label={"Ville"} value={GarageDetails.garage_ville} />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={toggleUpdate}
            sx={{
              mt: 2,
              backgroundColor: "#002654",
              "&:hover": {
                backgroundColor: "#ff224d",
              },
            }}
          >
            Modifier
          </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={()=>navigate(-1)}
            sx={{
              mt: 2,
              backgroundColor: "#002654",
              "&:hover": {
                backgroundColor: "#ff224d",
              },
            }}
          >
            Retour
          </Button>
        </Box>
      </Box>
    );
  };
  const renderGarageForm = () => {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProfileInput name="garage_profile" src={formData.garage_profile} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_name"
              label="Société"
              name="garage_name"
              autoFocus
              size="small"
              value={formData?.garage_name || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth size="small">
              <InputLabel id="garage_role_label">Rôle</InputLabel>
              <Select
                id="garage_role"
                labelId="garage_role_label"
                name="garage_role"
                value={formData.garage_role}
                label="Rôle"
                onChange={handleChange}
                required
              >
                <MenuItem value="agent">Agent dans le garage</MenuItem>
                <MenuItem value="gerant">Gérant du garage</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_first_name"
              label="Prénom"
              name="garage_first_name"
              size="small"
              value={formData?.garage_first_name || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_last_name"
              label="Nom de famille"
              name="garage_last_name"
              size="small"
              value={formData?.garage_last_name || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileInput label="KBIS du garage" name="garage_kbis" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileInput label="Identité du garage" name="garage_identity" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              onChange={handleChange}
              id="garage_email"
              label="Email"
              name="garage_email"
              size="small"
              value={formData?.garage_email || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_phone"
              label="Phone"
              name="garage_phone"
              size="small"
              placeholder="Ex: +33623456789"
              value={formData?.garage_phone || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_iban"
              label="IBAN"
              name="garage_iban"
              size="small"
              value={formData?.garage_iban || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_bic"
              label="BIC"
              name="garage_bic"
              size="small"
              value={formData?.garage_bic || ""}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_tva_imm"
              label="TVA IMM"
              name="garage_tva_imm"
              size="small"
              value={formData?.garage_tva_imm || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_n_siret"
              label="N° Siret"
              name="garage_n_siret"
              size="small"
              value={formData?.garage_n_siret || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_email_blue_glass"
              label="Email Blue Glass"
              name="garage_email_blue_glass"
              size="small"
              value={formData?.garage_email_blue_glass || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_code_postale"
              label="Code Postale"
              name="garage_code_postale"
              size="small"
              value={formData?.garage_code_postale || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_address"
              label="Address"
              name="garage_address"
              size="small"
              value={formData?.garage_address || ""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              onChange={handleChange}
              id="garage_ville"
              label="Ville"
              name="garage_ville"
              size="small"
              value={formData?.garage_ville || ""}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              mt: 2,
              backgroundColor: "#002654",
              "&:hover": {
                backgroundColor: "#ff224d",
              },
            }}
          >
            Validé
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="error"
            onClick={toggleUpdate}
            sx={{ mt: 2 }}
          >
            Annuler
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      <Header />
      <Box sx={{ marginTop: "100px", display: "flex" }}>
        <Sidebar />
        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            <AlertTitle>
              {alertSeverity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
          }}
        >
          <Paper elevation={3} sx={{ padding: "20px" }}>
            {!isUpdate ? renderGarageDetails() : renderGarageForm()}
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default GarageUpdate;
