import React from "react";
import {
  Box,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const SidebarSkeleton = () => {
  return (
    <Box>
      <Toolbar
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          left: 0,
        }}>
        <IconButton style={{ color: "white" }}>
          <Skeleton variant="circular" width={40} height={40} />
        </IconButton>
      </Toolbar>

      <Box
        sx={{
          width: "250px",
          backgroundColor: "#002654",
          color: "white",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 1000,
          overflowY: "auto",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 2,
          }}>
          <Skeleton variant="circular" width={64} height={64} />
          <Skeleton variant="text" width={100} height={30} sx={{ mt: 1 }} />
          <Skeleton
            variant="text"
            width={80}
            height={20}
            sx={{ color: "#ff224d" }}
          />
        </Box>

        <Skeleton
          variant="rectangular"
          width={200}
          height={40}
          sx={{ my: 2, mx: "auto", borderRadius: "8px" }}
        />

        <List>
          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circular" width={20} height={20} />
            </ListItemIcon>
            <ListItemText>
              <Skeleton variant="text" width={100} height={30} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circular" width={20} height={20} />
            </ListItemIcon>
            <ListItemText>
              <Skeleton variant="text" width={100} height={30} />
            </ListItemText>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circular" width={20} height={20} />
            </ListItemIcon>
            <ListItemText>
              <Skeleton variant="text" width={100} height={30} />
            </ListItemText>
          </ListItem>

          <Box>
            <ListItem>
              <ListItemIcon>
                <Skeleton variant="circular" width={20} height={20} />
              </ListItemIcon>
              <ListItemText>
                <Skeleton variant="text" width={100} height={30} />
              </ListItemText>
            </ListItem>
            <Collapse in={false}>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <Skeleton variant="circular" width={20} height={20} />
                  </ListItemIcon>
                  <ListItemText>
                    <Skeleton variant="text" width={100} height={30} />
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Skeleton variant="circular" width={20} height={20} />
                  </ListItemIcon>
                  <ListItemText>
                    <Skeleton variant="text" width={100} height={30} />
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Skeleton variant="circular" width={20} height={20} />
                  </ListItemIcon>
                  <ListItemText>
                    <Skeleton variant="text" width={100} height={30} />
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </Box>
        </List>

        <Box sx={{ position: "absolute", bottom: 5, width: "100%" }}>
          <Divider sx={{ bgcolor: "white" }} />
          <ListItem>
            <ListItemIcon>
              <Skeleton variant="circular" width={20} height={20} />
            </ListItemIcon>
            <ListItemText>
              <Skeleton variant="text" width={100} height={30} />
            </ListItemText>
          </ListItem>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarSkeleton;
