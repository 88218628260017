import { BASE_URL } from "../constants/BaseUrl";

const uploadFileService = async (files, usertype) => {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    const response = await fetch(`${BASE_URL}/UploadFile?usertype=${usertype}`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Error uploading files");
    }
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error;
  }
};

export default uploadFileService;
