import React from "react";
import Header from "../components/Header";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
// import carImage from "../assets/car.png";

const NoPage = () => {
  return (
    <Box
      sx={{
        marginTop: "90px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "70vh",
        padding: "16px",
        textAlign: "center",
        position: "relative",
      }}>
      <Header />
      {/* Background Image with Overlay */}
      {/* Content Wrapper */}
      <Box
        sx={{
          zIndex: 1,
          maxWidth: "100%",
          marginBottom: "32px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: "6rem",
            fontWeight: "bold",
            color: "#002654",
            marginBottom: "16px",
          }}>
          404
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "2rem",
            fontWeight: "bold",
            color: "#002654",
            marginBottom: "16px",
          }}>
          Oops! Page non trouvée
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ marginBottom: "32px" }}>
          La page que vous recherchez a peut-être été supprimée, elle portait
          son nom modifié ou est temporairement indisponible.
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to="/"
          sx={{
            backgroundColor: "#002654",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#ff224d",
            },
          }}>
          Retourner à l'accueil
        </Button>
      </Box>
    </Box>
  );
};

export default NoPage;
