import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  Snackbar,
  Alert,
  Divider,
  AlertTitle,
} from "@mui/material";
import { Person2, Visibility, VisibilityOff } from "@mui/icons-material";
import { fetchUserDetails } from "../services/LoginService";
import { getMyProfileById } from "../services/MyProfileService";
import { PasswordService, updateUserDetails } from "../services/ProfileService";

import Header from "../components/Header";
import Sidebar from "../components/SideBar";

const ProfilePage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [myProfile, setMyProfile] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);

        const profileData = await getMyProfileById(data.usertype, data.userid);
        setMyProfile(profileData);

        // Set initial form values
        switch (data.usertype) {
          case "admin":
            setFirstName(profileData.admin_first_name || "");
            setLastName(profileData.admin_last_name || "");
            setEmail(profileData.admin_email || "");
            break;
          case "gestionnaire":
            setFirstName(profileData.gestionnaire_first_name || "");
            setLastName(profileData.gestionnaire_last_name || "");
            setEmail(profileData.gestionnaire_email || "");
            break;
          case "call_center":
            setFirstName(profileData.cc_first_name || "");
            setLastName(profileData.cc_last_name || "");
            setEmail(profileData.cc_email || "");
            break;
          case "garage":
            setFirstName(profileData.garage_first_name || "");
            setLastName(profileData.garage_last_name || "");
            setEmail(profileData.garage_email || "");
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  if (!userDetails || !myProfile) {
    return null; // Return a loading indicator or handle accordingly
  }

  let userTypeLabel = "Unknown";
  let profileImageField = null;
  let profileImagePath = "";

  switch (userDetails.usertype) {
    case "admin":
      userTypeLabel = "Admin";
      profileImageField = myProfile.admin_profile;
      break;
    case "gestionnaire":
      userTypeLabel = "Gestionnaire";
      profileImageField = myProfile.gestionnaire_profile;
      break;
    case "call_center":
      userTypeLabel = "Call Center";
      profileImageField = myProfile.cc_profile;
      break;
    case "garage":
      userTypeLabel = "Garage";
      profileImageField = myProfile.garage_profile;
      break;
    default:
      break;
  }

  if (profileImageField) {
    profileImagePath = `${process.env.REACT_APP_BACK_BASE_URL}/${profileImageField}`;
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handlePasswordChange = async () => {
    try {
      const response = await PasswordService({
        usertype: userDetails.usertype,
        userid: userDetails.userid,
        currentPassword,
        newPassword,
      });

      setAlertMessage(response.message);
      setAlertSeverity("success");
      setAlertOpen(true);

      setCurrentPassword("");
      setNewPassword("");
      setShowPassword(false);
    } catch (error) {
      console.error("Error changing password:", error);

      setAlertMessage(
        error.response?.data?.message || "Une erreur s'est produite"
      );
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleUpdateDetails = async () => {
    try {
      const response = await updateUserDetails({
        usertype: userDetails.usertype,
        userid: userDetails.userid,
        firstName,
        lastName,
        email,
      });

      setAlertMessage(response.message);
      setAlertSeverity("success");
      setAlertOpen(true);
    } catch (error) {
      console.error("Error updating user details:", error);

      setAlertMessage(
        error.response?.data?.message || "Une erreur s'est produite"
      );
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  return (
    <Box component="div">
      <Header />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Sidebar />
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            height: "500px",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
            display: "flex",
            justifyContent: "start",
          }}
          component="div">
          {/* Profile Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
            component="div">
            {/* User Type */}
            <Typography variant="subtitle1">{userTypeLabel}</Typography>
            {/* Profile Image */}
            <Avatar
              alt="Profile Image"
              sx={{ width: 75, height: 75, mb: 2 }}
              src={profileImagePath}>
              {!profileImagePath && <Person2 />}
            </Avatar>

            {/* User Details */}
            <Box sx={{ mt: 4, width: "100%" }}>
              <Divider />
              <Typography variant="h6">
                Modifier les détails de l'utilisateur
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="user_first_name">Prénom</InputLabel>
                <OutlinedInput
                  id="user_first_name"
                  name="user_first_name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label="First Name"
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="user_last_name">Nom de famille</InputLabel>
                <OutlinedInput
                  id="user_last_name"
                  name="user_last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label="Last Name"
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="user_email">Email</InputLabel>
                <OutlinedInput
                  id="user_email"
                  name="user_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                />
              </FormControl>
              {/* Save Button (for user details update) */}
              <Box sx={{ display: "flex", justifyContent: "start", mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateDetails}
                  sx={{
                    mt: 2,
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}>
                  Mettre à jour les détails
                </Button>
              </Box>
            </Box>

            {/* Password Change Section */}
            <Box sx={{ mt: 4, width: "100%" }}>
              <Divider />

              <Typography variant="h6">Changer le mot de passe</Typography>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="current_password">
                  Mot de passe actuel
                </InputLabel>
                <OutlinedInput
                  id="current_password"
                  name="current_password"
                  type={showPassword ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe actuel"
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="new_password">
                  Nouveau mot de passe
                </InputLabel>
                <OutlinedInput
                  id="new_password"
                  name="new_password"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  label="Nouveau mot de passe"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>

              {/* Save Button (for password change) */}
              <Box sx={{ display: "flex", justifyContent: "start", mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordChange}
                  sx={{
                    mt: 2,
                    backgroundColor: "#002654",
                    "&:hover": {
                      backgroundColor: "#ff224d",
                    },
                  }}>
                  Changer le mot de passe
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Snackbar
          open={alertOpen}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
          <Alert onClose={handleAlertClose} severity={alertSeverity}>
            <AlertTitle>
              {alertSeverity === "success" ? "Succès" : "Erreur"}
            </AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default ProfilePage;
