// services/MyFolderService.js

import { BASE_URL } from "../constants/BaseUrl";

export const getMyFolderData = async (usertype, userid, cc_role) => {
  try {
    const response = await fetch(`${BASE_URL}/MyFolder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ usertype, userid, cc_role }),
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorMessage = await response.json();
      console.error(
        "Failed to retrieve my Folder data. Error message:",
        errorMessage.message
      );
      throw new Error(errorMessage.message);
    }
  } catch (error) {
    console.error("Error retrieving my Folder data:", error);
    throw error;
  }
};
