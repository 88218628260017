import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, FormControl, InputLabel, InputAdornment, OutlinedInput, Chip, IconButton, Button, Snackbar, Alert, AlertTitle } from "@mui/material";
import { getUserData, blockUser } from "../services/UserTableService"; // Import blockUser function
import { PasswordService, updateUserDetails } from "../services/ProfileService";
import {  Visibility, VisibilityOff } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function GaragePage() {
  const [GarageData, setGarageData] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertOpen, setAlertOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();

  const fetchGarageData = async () => {
    try {
      // Fetch Garage data from the backend
      const data = await getUserData("garage");
      // Ensure each row has a unique id
      const formattedData = data.map((row) => ({
        ...row,
        id: row.garage_id, // Assuming garage_id is the unique identifier from your backend
      }));
      setGarageData(formattedData);
    } catch (error) {
      console.error("Error fetching Garage data:", error);
    }
  };

  useEffect(() => {
    // Fetch Garage data when component mounts
    fetchGarageData();
  }, []);

  const handleBlockUser = async (userId, isBlocked) => {
    try {
      await blockUser(userId, isBlocked, "garage");
      // Set success alert
      setAlertMessage(
        `L'utilisateur a été ${isBlocked ? "débloqué" : "bloqué"} avec succès.`
      );
      setAlertSeverity("success");
      setAlertOpen(true);
      // Refresh the data to reflect the blocked/unblocked user
      fetchGarageData();
    } catch (error) {
      // Set error alert
      setAlertMessage(error.message);
      setAlertSeverity("error");
      setAlertOpen(true);
      console.error("Error blocking/unblocking user:", error);
    }
  };

  const handleUpdateDetails = async () => {
    try {
      const response = await updateUserDetails({
        usertype: "garage",
        userid: selectedUser.garage_id,
        firstName,
        lastName,
        email,
      });
      setAlertMessage(response.message);
      setAlertSeverity("success");
      setAlertOpen(true);
    } catch (error) {
      console.error("Error updating user details:", error);

      setAlertMessage(
        error.response?.data?.message || "Une erreur s'est produite"
      );
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handlePasswordChange = async () => {
    try {
      const response = await PasswordService({
        usertype: "garage",
        userid: selectedUser.garage_id,
        currentPassword,
        newPassword,
      });

      setAlertMessage(response.message);
      setAlertSeverity("success");
      setAlertOpen(true);

      setCurrentPassword("");
      setNewPassword("");
      setShowPassword(false);
    } catch (error) {
      console.error("Error changing password:", error);

      setAlertMessage(
        error.response?.data?.message || "Une erreur s'est produite"
      );
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleOpen = (user) => {
    setSelectedUser(user);
    setFirstName(user.garage_first_name);
    setLastName(user.garage_last_name);
    setEmail(user.garage_email);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);


  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // Define columns for the DataGrid
  const columns = [
    { field: "garage_id", headerName: "ID", width: 150 },
    { field: "garage_name", headerName: "Nom de Garage", width: 150 },
    { field: "garage_first_name", headerName: "Prénom", width: 150 },
    { field: "garage_last_name", headerName: "Nom", width: 150 },
    {
      field: "dossier_count",
      headerName: "Nombre de Dossiers",
      width: 150,
      renderCell: (params) => (
        <Chip
          label={`N° : ${params.value}`}
          variant="contained"
          size="small"
          sx={{
            fontWeight: "bold",
            color: "white", // Custom border color (orange)
            backgroundColor: "#ff9915", // Custom text color (orange)
            padding: "5px", // Custom padding
          }}
        />
      ),
    },
    { field: "garage_email", headerName: "Email", width: 200 },
    {
      field: "block",
      headerName: "Bloquer/Débloquer",
      width: 150,
      renderCell: (params) => (
        <Button
        size="small"
          onClick={() =>
            handleBlockUser(params.row.garage_id, params.row.is_blocked)
          }
          sx={{
            backgroundColor: params.row.is_blocked ? "red" : "green",
            color: "white",
            border: "none",
            borderRadius: "4px",
            padding: "5px 10px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: params.row.is_blocked ? "red" : "green",
            },
          }}>
          {params.row.is_blocked ? "Débloquer" : "Bloquer"}
        </Button>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <IconButton
          sx={{
            backgroundColor: "#002654",
            "&:hover": {
              backgroundColor: "#ff224d",
            },
            color: "white",
            borderRadius: "4px",
            padding: "4px",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/UpdateGarage/${params.row.garage_id}`)}
        >
          <Visibility fontSize="30"/>
        </IconButton>
      ),
    },
  ];

  return (
    <Box component="div">
      <Header />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Sidebar />
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            height: "75vh",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
          }}
          component="div">
          <DataGrid  sortModel={[
              {
                field: 'dossier_id',
                sort: 'desc', // or 'desc' for descending order
              },
            ]} rows={GarageData} columns={columns} />
        </Box>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "10px solid transparent",
            boxShadow: 24,
            p: 1,
            borderRadius: 4,
          }}>
          {/* Profile Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              mb: 2,
            }}
            component="div">
            {/* User Details */}
            <Box sx={{ width: "100%" }}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="user_first_name">Prénom</InputLabel>
                <OutlinedInput
                  id="user_first_name"
                  name="user_first_name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label="First Name"
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="user_last_name">Nom de famille</InputLabel>
                <OutlinedInput
                  id="user_last_name"
                  name="user_last_name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label="Last Name"
                />
              </FormControl>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="user_email">Email</InputLabel>
                <OutlinedInput
                  id="user_email"
                  name="user_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateDetails}
                sx={{
                  mt: 2,
                  backgroundColor: "#002654",
                  "&:hover": {
                    backgroundColor: "#ff224d",
                  },
                }}>
                Mettre à jour
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="current_password">
                  Mot de passe actuel
                </InputLabel>
                <OutlinedInput
                  id="current_password"
                  name="current_password"
                  type={showPassword ? "text" : "password"}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe actuel"
                />
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{ mt: 2 }}>
                <InputLabel htmlFor="new_password">
                  Nouveau mot de passe
                </InputLabel>
                <OutlinedInput
                  id="new_password"
                  name="new_password"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  label="Nouveau mot de passe"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePasswordChange}
                sx={{
                  mt: 2,
                  backgroundColor: "#002654",
                  "&:hover": {
                    backgroundColor: "#ff224d",
                  },
                }}>
                Changer
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          <AlertTitle>
            {alertSeverity === "success" ? "Succès" : "Erreur"}
          </AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default GaragePage;
