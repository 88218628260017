import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { getUserData } from "../services/UserTableService";
import { fetchUserDetails } from "../services/LoginService";

function CallCenterAgentPage() {
  const [CallCenterAgentData, setCallCenterAgentData] = useState([]);
  const [UserDetails, setUserDetails] = useState(null);

  // Fetch user details when component mounts
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);
        fetchCallCenterAgentData(data.userid); // Pass userid to fetchCallCenterAgentData
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  const fetchCallCenterAgentData = async (userid) => {
    try {
      // Fetch CallCenterAgent data from the backend
      const data = await getUserData("call_center_agent", userid); // Pass userid to getUserData
      const formattedData = data.map((row, index) => ({
        ...row,
        id: row.cc_id, 
      }));
      setCallCenterAgentData(formattedData);
    } catch (error) {
      console.error("Error fetching CallCenterAgent data:", error);
    }
  };

  // Define columns for the DataGrid
  const columns = [
    { field: "cc_id", headerName: "ID", width: 150 },
    {
      field: "cc_call_center_name",
      headerName: "Nom du Centre d'Appels",
      width: 200,
    },
    { field: "cc_first_name", headerName: "Prénom", width: 150 },
    { field: "cc_last_name", headerName: "Nom", width: 150 },
    { field: "cc_email", headerName: "Email", width: 200 },
    {
      field: "cc_role",
      headerName: "Rôle",
      width: 150,
    },
  ];

  // Function to dynamically set row class name based on cc_role
  const getRowClassName = (params) => {
    return params.row.cc_role === "gerant" ? "gerant-row" : "";
  };

  return (
    <Box component="div">
      <Header />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Sidebar />
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            height: "75vh",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
          }}
          component="div">
          <DataGrid
           sortModel={[
            {
              field: 'dossier_id',
              sort: 'desc', // or 'desc' for descending order
            },
          ]}
            rows={CallCenterAgentData}
            columns={columns}
            getRowClassName={getRowClassName} // Apply custom row class name
          />
        </Box>
      </Box>

      {/* Inline styles for the gerant row */}
      <style>
        {`.gerant-row {
          background-color: rgba(128, 0, 128, 0.1) !important; /* Transparent purple */
          color: black !important;
        }`}
      </style>
    </Box>
  );
}

export default CallCenterAgentPage;
