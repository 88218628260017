import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Avatar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  ListItemButton,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import Logout from "../services/LogoutService";
import SidebarSkeleton from "../components/SideBarSkeleton";
import WebSocket from "../components/Websocket";
import { fetchUserDetails } from "../services/LoginService";
import { getMyProfileById } from "../services/MyProfileService";
import Loading from "../components/Loading"
import {
  Person2,
  Folder,
  CreateNewFolder,
  FolderShared,
  SupervisedUserCircle,
  ManageAccounts,
  PersonAdd,
  WifiCalling,
  Garage,
  Logout as LogoutIcon,
  Menu,
  Close,
} from "@mui/icons-material";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [userDetails, setUserDetails] = useState(null);
  const [myProfile, setMyProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch user details
  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const data = await fetchUserDetails();
        setUserDetails(data);

        const myProfile = await getMyProfileById(data.usertype, data.userid);
        setMyProfile(myProfile);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    getUserDetails();
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading || !userDetails ) {
    if (isMobile ) {
      return null;
    }else{
      return <SidebarSkeleton />;
    }
    
  }

  const { usertype, cc_role } = userDetails;
  let profileImageField;
  let profileImagePath;

  let userName;

  switch (userDetails.usertype) {
    case "gestionnaire":
      profileImageField = myProfile?.gestionnaire_profile;
      userName = `${myProfile?.gestionnaire_first_name || ""} ${
        myProfile?.gestionnaire_last_name || ""
      }`;
      break;
    case "garage":
      profileImageField = myProfile?.garage_profile;
      userName = `${myProfile?.garage_first_name || ""} ${
        myProfile?.garage_last_name || ""
      }`;
      break;
    case "call_center":
      profileImageField = myProfile?.cc_profile;
      userName = `${myProfile?.call_center_first_name || ""} ${
        myProfile?.call_center_last_name || ""
      }`;
      break;
    default:
      profileImageField = null;
      userName = "";
  }

  if (profileImageField) {
    profileImagePath = `${process.env.REACT_APP_BACK_BASE_URL}/${profileImageField}`;
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLogout = async () => {
    try {
      await Logout();
      window.location.href = "/login";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box>
      {/* MOBILE MENU TOOGLE */}
      <Toolbar
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 1000,
          left: 0,
        }}>
        {isMobile && (
          <IconButton onClick={handleToggle} style={{ color: "white" }}>
            {open ? <Close /> : <Menu />}
          </IconButton>
        )}
      </Toolbar>

      {/* DESKTOP MENU */}
      <Box
        sx={{
          width: isMobile ? (open ? "70%" : 0) : "250px",
          backgroundColor: "#002654",
          color: "white",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          zIndex: 1000,
          overflowY: "auto",
          transition: "width 0.3s ease",
        }}>
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "10px",
              paddingRight: "16px",
            }}>
            <IconButton onClick={handleToggle} style={{ color: "white" }}>
              <Close />
            </IconButton>
          </Box>
        )}

        {/* USER AVATAR */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 2,
          }}>
          <Avatar
            alt="Profile Image"
            sx={{ mt: 2, width: 64, height: 64 }}
            src={profileImagePath}>
            {!profileImagePath && <Person2 />}{" "}
          </Avatar>

          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            {userName}
          </Typography>
        </Box>

        {/* CREATE USER BUTTON */}
        <Button
          component={RouterLink}
          to="/CreateFolder"
          variant="contained"
          sx={{
            textDecoration: "none",
            color: "inherit",
            marginRight: 1,
            marginLeft: 1,
            display: "flex",
            alignItems: "center",
            backgroundColor: "rgba(255, 34, 77, 0.8)",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#ff224d",
            },
          }}>
          <CreateNewFolder sx={{ fontSize: 20, color: "white", mr: 3 }} />
          Créer un dossier
        </Button>

        {/* LISTE */}
        <List>
          {(usertype === "admin" || usertype === "gestionnaire") && (
            <ListItem
              button
              component={RouterLink}
              to="/Home"
              sx={{
                textDecoration: "none",
                color: "inherit",
                paddingLeft: 2,
                "&:hover": {
                  backgroundColor: "rgba(255, 34, 77, 0.8)",
                },
                "&:focus": {
                  backgroundColor: "rgba(255, 34, 77)",
                },
              }}>
              <ListItemIcon style={{ color: "white" }}>
                <Folder sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText primary="Tout les dossier" />
            </ListItem>
          )}
          <ListItem
            button
            component={RouterLink}
            to="/MyFolders"
            sx={{
              textDecoration: "none",
              color: "inherit",
              paddingLeft: 2,
              "&:hover": {
                backgroundColor: "rgba(255, 34, 77, 0.8)",
              },
              "&:focus": {
                backgroundColor: "rgba(255, 34, 77)",
              },
            }}>
            <ListItemIcon style={{ color: "white" }}>
              <FolderShared sx={{ fontSize: 20 }} />
            </ListItemIcon>
            <ListItemText primary="Mes dossier" />
          </ListItem>
          {cc_role === "gerant" && (
            <ListItem
              button
              component={RouterLink}
              sx={{
                textDecoration: "none",
                color: "inherit",
                paddingLeft: 2,
                "&:hover": {
                  backgroundColor: "rgba(255, 34, 77, 0.8)",
                },
                "&:focus": {
                  backgroundColor: "rgba(255, 34, 77)",
                },
              }}
              to="/GerantTable">
              <ListItemIcon style={{ color: "white" }}>
                <WifiCalling sx={{ fontSize: 20 }} />
              </ListItemIcon>
              <ListItemText primary="Liste des agents" />
            </ListItem>
          )}

          {/* ADMIN MENU */}
          {usertype === "admin" && (
            <Box>
              <ListItemButton
                onClick={handleClick}
                style={{
                  "&:hover": { backgroundColor: "rgba(255, 34, 77, 0.8)" },
                }}>
                <ListItemIcon style={{ color: "white" }}>
                  <SupervisedUserCircle sx={{ fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText primary="Utilisateur" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit={false}>
                <List disablePadding>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/CreateUser"
                    sx={{
                      textDecoration: "none",
                      paddingLeft: 4,
                      color: "inherit",
                      "&:hover": {
                        backgroundColor: "rgba(255, 34, 77, 0.8)",
                      },
                      "&:focus": {
                        backgroundColor: "rgba(255, 34, 77)",
                      },
                    }}>
                    <ListItemIcon style={{ color: "white" }}>
                      <PersonAdd sx={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText primary="Cree utilisateur" />
                  </ListItem>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/CallCenterTable"
                    sx={{
                      textDecoration: "none",

                      paddingLeft: 4,
                      color: "inherit",
                      "&:hover": {
                        backgroundColor: "rgba(255, 34, 77, 0.8)",
                      },
                      "&:focus": {
                        backgroundColor: "rgba(255, 34, 77)",
                      },
                    }}>
                    <ListItemIcon style={{ color: "white" }}>
                      <WifiCalling sx={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText primary="Centre d'appel" />
                  </ListItem>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/GarageTable"
                    sx={{
                      textDecoration: "none",

                      paddingLeft: 4,
                      color: "inherit",
                      "&:hover": {
                        backgroundColor: "rgba(255, 34, 77, 0.8)",
                      },
                      "&:focus": {
                        backgroundColor: "rgba(255, 34, 77)",
                      },
                    }}>
                    <ListItemIcon style={{ color: "white" }}>
                      <Garage sx={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText primary="Garage" />
                  </ListItem>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/GestionnaireTable"
                    sx={{
                      textDecoration: "none",

                      paddingLeft: 4,
                      color: "inherit",
                      "&:hover": {
                        backgroundColor: "rgba(255, 34, 77, 0.8)",
                      },
                      "&:focus": {
                        backgroundColor: "rgba(255, 34, 77)",
                      },
                    }}>
                    <ListItemIcon style={{ color: "white" }}>
                      <ManageAccounts sx={{ fontSize: 20 }} />
                    </ListItemIcon>
                    <ListItemText primary="Gestionnaire" />
                  </ListItem>
                </List>
              </Collapse>
            </Box>
          )}
        </List>

        {/* FIXED BOTTOM */}
        <Box sx={{ position: "absolute", bottom: 5, width: "100%" }}>
        {/* {usertype === "admin" &&(
          <Box>
          <Divider sx={{ bgcolor: "white" }} />
          <Box sx={{display:"flex", alignContent:"center", justifyContent:"center", my:1, mx:4}}>
           <WebSocket />
          </Box>
          </Box>
        )} */}
          <Divider sx={{ bgcolor: "white" }} />

          <ListItem
            button
            component={RouterLink}
            to="/Profile"
            sx={{
              paddingLeft: 2,
              "&:hover": {
                backgroundColor: "rgba(255, 34, 77, 0.8)",
              },
              textDecoration: "none",
              color: "inherit",
            }}>
            <ListItemIcon sx={{ color: "white" }}>
              <PersonIcon sx={{ fontSize: 20 }} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              paddingLeft: 2,
              "&:hover": {
                backgroundColor: "rgba(255, 34, 77, 0.8)",
              },
              textDecoration: "none",
              color: "inherit",
            }}>
            <ListItemIcon sx={{ color: "white" }}>
              <LogoutIcon sx={{ fontSize: 20 }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
