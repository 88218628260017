import React from "react";
import {
  Skeleton,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import SidebarSkeleton from "../components/SideBarSkeleton";
const DossierSkeleton = () => {
  return (
    <Box>
      <AppBar position="fixed" sx={{ backgroundColor: "#002654", zIndex: 1 }}>
        <Toolbar sx={{ alignItems: "center", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Skeleton variant="text" width={100} height={40} />
          </Box>
          <Skeleton variant="circular" width={80} height={80} ml={8} />
        </Toolbar>
      </AppBar>
      <SidebarSkeleton />
      <Box sx={{ marginTop: "120px", display: "flex" }}>
        <Box
          sx={{
            width: window.innerWidth < 768 ? "95%" : "80%",
            marginLeft: window.innerWidth < 768 ? "10px" : "260px",
            marginRight: "10px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}>
          <Box sx={{ width: "100%" }}>
            <Skeleton variant="rectangular" height={40} />
          </Box>

          <Box
            sx={{
              padding: 3,
              borderRadius: 2,
              backgroundColor: "#fff",
              color: "#002654",
              position: "relative",
              overflow: "hidden",
              boxShadow: "0px 1px 1px #A5A5A5",
            }}>
            <Skeleton variant="text" width="50%" height={30} />
            <Skeleton variant="text" width="30%" height={30} />
            <Skeleton variant="rectangular" height={40} />
            <Divider />
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 1,
                    color: "#002654",
                    textDecoration: "underline",
                  }}>
                  <Skeleton variant="text" />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 1,
                    color: "#002654",
                    textDecoration: "underline",
                  }}>
                  <Skeleton variant="text" />
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  mb: 2,
                  boxShadow: "0px 1px 1px #A5A5A5",
                }}>
                <CardHeader title={<Skeleton variant="text" width="50%" />} />
                <Divider />
                <CardContent></CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  mb: 2,
                  boxShadow: "0px 1px 1px #A5A5A5",
                }}>
                <CardHeader title={<Skeleton variant="text" width="50%" />} />
                <Divider />
                <CardContent></CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  mb: 2,
                  boxShadow: "0px 1px 1px #A5A5A5",
                }}>
                <CardHeader title={<Skeleton variant="text" width="50%" />} />
                <Divider />
                <CardContent></CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  mb: 2,
                  boxShadow: "0px 1px 1px #A5A5A5",
                }}>
                <CardHeader title={<Skeleton variant="text" width="50%" />} />
                <Divider />
                <CardContent></CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  mb: 2,
                  boxShadow: "0px 1px 1px #A5A5A5",
                }}>
                <CardHeader title={<Skeleton variant="text" width="50%" />} />
                <Divider />
                <CardContent></CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  backgroundColor: "#fff",
                  mb: 2,
                  boxShadow: "0px 1px 1px #A5A5A5",
                }}>
                <CardHeader title={<Skeleton variant="text" width="50%" />} />
                <Divider />
                <CardContent></CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default DossierSkeleton;
