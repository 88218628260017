import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { MoreVert } from "@mui/icons-material";
const ActionsButton = ({dataMenu}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {
            dataMenu && dataMenu.map((item, index) => {
                return (
                    <MenuItem key={index} onClick={()=>{item.action();handleClose();}} disabled={Boolean(item.disabled)}>
                        <item.Icon sx={{marginRight:1}} />{item.label}
                    </MenuItem>
                )
            })
        }
      </Menu>
    </div>
  );
};

export default ActionsButton;
