import React, { useState, useEffect } from "react";
import { LoginUser, fetchUserDetails } from "../services/LoginService";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import {
  ShieldRounded,
  CalendarTodayRounded,
  CampaignRounded,
} from "@mui/icons-material"; // Example icons

import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Alert,
  AlertTitle,
  Snackbar,
  CssBaseline,
  Paper,
  Avatar,
} from "@mui/material";

import Header from "../components/Header";
import Logo from "../assets/bleu-glass.svg";
import BkgImage from "../assets/bkg.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const defaultTheme = createTheme();
const LoginForm = ({ updateUserDetails }) => {
  // CONST
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // DATA LIST
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // HANDLE DATA CHANGE FUNCTIONS
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // HANDLE ALERT  FUNCTIONS
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  // LOGIN HANDLE SUBMIT FUNCTION
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await LoginUser(formData);
      await updateUserDetails(); // Update user details state in App component
      const UserDetails = await fetchUserDetails(); // Fetch user details

      if (
        UserDetails.usertype === "admin" ||
        UserDetails.usertype === "gestionnaire"
      ) {
        navigate("/Home");
      } else {
        navigate("/MyFolders");
      }
    } catch (error) {
      setAlertMessage(error.message);
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const textVariants = {
    hidden: { opacity: 0, y: 30, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  const iconStyles = {
    color: "#ff224d",
    fontSize: 30,
    marginRight: 1,
    opacity: 0.8,
  };

  const services = [
    {
      text: "Relance des Assurances pour les Paiements",
      icon: <ShieldRounded sx={iconStyles} />, // Choose the icon that fits
    },
    {
      text: "Gestion de Rendez-vous Pare-brise",
      icon: <CalendarTodayRounded sx={iconStyles} />, // Choose the icon that fits
    },
    {
      text: "Déclaration Sinistres BDG",
      icon: <CampaignRounded sx={iconStyles} />, // Choose the icon that fits
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Header />
     
      <Grid  component="main" sx={{ display: "flex", height: "100%" }}>

        {isMobile ? null : (
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundColor: "#fff",
              backgroundImage: `url(${BkgImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height:"100vh",
              width: "100%",
              position: "relative",
            }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "40%",
                transform: "translate(-50%, -50%)",
                color: "#fff",
                textAlign: { xs: "center", sm: "left" }, // Center text on xs screens, left on sm and up
                display: "block", // Always block on larger screens
              }}>
              <Box
                sx={{
                  textAlign: "left",
                  gap: 2,
                }}>
                {services.map((service, index) => (
                  <motion.div
                    key={index}
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 1, delay: index * 1 }}>
                    <motion.div
                      animate={{ scale: [1, 1.05, 1] }}
                      transition={{
                        repeat: Infinity,
                        duration: 6,
                        ease: "easeInOut",
                        delay: 3,
                      }}>
                      <Box
                        sx={{
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          borderRadius: "2px",
                          px: 2,
                          py: 1,
                          mb: 2,
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          width: "100%", // Full width for large screens
                        }}>
                        {service.icon}
                        <Typography
                          sx={{
                            color: "#002654",
                            fontSize: { sm: "1rem", md: "1.7rem" }, // Responsive font size
                            fontWeight: 500,
                            lineHeight: 1.5,
                            letterSpacing: "0.1em",
                            textAlign: "left", // Always left-aligned
                          }}>
                          {service.text}
                        </Typography>
                      </Box>
                    </motion.div>
                  </motion.div>
                ))}
              </Box>
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{ width:{xs:"700px" , md:"100%"}, mt: "90px", height:"100%", boxShadow: 0 }}
          component={Paper}
          elevation={6}
          square>
          <Box
            sx={{
              mt: 12,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap:4
            }}>
            <Avatar
              alt="Bleu Glass"
              src={Logo}
              sx={{ width: 80, height: 80 }}
            />
            <Typography component="h1" variant="h5">
              Connectez-vous pour accéder à votre compte
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}>
              <TextField
                required
                fullWidth
                onChange={handleChange}
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
              />
              <FormControl
                margin="normal"
                fullWidth
                variant="outlined"
                required>
                <InputLabel htmlFor="password">Mot de passe</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe"
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  backgroundColor: "#002654",
                  "&:hover": {
                    backgroundColor: "#ff224d",
                  },
                }}>
                Connexion
              </Button>
            </Box>
          </Box>
        </Grid>

      </Grid>

      <Snackbar
        open={alertOpen}
        autoHideDuration={10000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "buttom", horizontal: "right" }}>
        <Alert onClose={handleAlertClose} severity={alertSeverity}>
          <AlertTitle>
            {alertSeverity === "success" ? "Succès" : "Erreur"}
          </AlertTitle>
          {alertMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default LoginForm;
