import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { fetchUserDetails } from "./services/LoginService";

import Login from "./pages/Login";
import Home from "./pages/Home";
import CreateFolder from "./pages/CreateFolder";
import MyFolders from "./pages/MyFolders";
import CreateUser from "./pages/CreateUser";
import GarageTable from "./pages/GarageTable";
import GestionnaireTable from "./pages/GestionnaireTable";
import CallCenterTable from "./pages/CallCenterTable";
import GerantTable from "./pages/GerantTable";
import Dossier from "./pages/Dossier";
import Profile from "./pages/Profile";
import NoPage from "./pages/NoPage";
import Loading from "./components/Loading";
import GarageUpdate from "./pages/GarageUpdate";

function App() {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const updateUserDetails = async () => {
    try {
      const data = await fetchUserDetails();
      setUserDetails(data);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setUserDetails(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateUserDetails();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const isAuthenticated = !!userDetails;
  const isAdmin = userDetails && userDetails.usertype === "admin";
  const isGestionnaire = userDetails && userDetails.usertype === "gestionnaire";
  const isCallCenter = userDetails && userDetails.usertype === "call_center";


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={<Login updateUserDetails={updateUserDetails} />}
          />
          <Route
            path="/login"
            element={<Login updateUserDetails={updateUserDetails} />}
          />

          {isAuthenticated ? (
            <>
              {(isAdmin || isGestionnaire) && (
                <>
                  <Route path="/Home" element={<Home />} />
                </>
              )}
              <Route path="/Dossier/:id" element={<Dossier />} />
              <Route path="/CreateFolder" element={<CreateFolder />} />
              <Route path="/GerantTable" element={<GerantTable />} />
              <Route path="/MyFolders" element={<MyFolders />} />
              <Route path="/Profile" element={<Profile />} />

              {isAdmin && (
                <>
                  <Route path="/CreateUser" element={<CreateUser />} />
                  <Route path="/UpdateGarage/:id" element={<GarageUpdate />} />
                  <Route path="/GarageTable" element={<GarageTable />} />
                  <Route
                    path="/GestionnaireTable"
                    element={<GestionnaireTable />}
                  />
                  <Route
                    path="/CallCenterTable"
                    element={<CallCenterTable />}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Route path="/Home" element={<Navigate to="/login" />} />
              <Route path="/CreateUser" element={<Navigate to="/login" />} />
              <Route path="/GerantTable" element={<Navigate to="/login" />} />
              <Route path="/CreateFolder" element={<Navigate to="/login" />} />
              <Route path="/GarageTable" element={<Navigate to="/login" />} />
              <Route
                path="/GestionnaireTable"
                element={<Navigate to="/login" />}
              />
              <Route
                path="/CallCenterTable"
                element={<Navigate to="/login" />}
              />
              <Route path="/Dossier/:id" element={<Navigate to="/login" />} />
              <Route path="/MyFolders" element={<Navigate to="/login" />} />
            </>
          )}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
